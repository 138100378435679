import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Axios from 'axios'

Vue.config.productionTip = false

Vue.prototype.$http=Axios;
var url;var address=window.location.href;
console.log(address);
if(window.location.href.includes('localhost')){
  url="http://icon-micro.local";
}else{
  url="";
}
Vue.prototype.$url=url;

new Vue({
  router,
  store,
  vuetify,
  Axios,
  render: h => h(App)
}).$mount('#app')
