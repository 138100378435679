<template>
  <v-app>

    <!-- <div>
      <v-img :src="$url+'/images/icon.jpg'" contain></v-img>
    </div> -->
    <div class="mt-16 text-center" v-if="loading">
      <v-progress-circular
        :width="3"
        color="blue"
        indeterminate
      ></v-progress-circular>
    </div>
    <template v-else>
      <div class="pa-10" v-if="!loggedIn">
        <v-sheet
          width="500"
          elevation="4"
          class="pa-5 mx-auto text-center"
        >
          <h2>Please enter your password</h2>
          <v-text-field
            v-model="password"
            type="password"
            outlined
            class="mt-3"
            label="Staging password"
            @keyup.enter="login"
          ></v-text-field>
          <v-btn color="primary" @click="login">Log In</v-btn>
        </v-sheet>

        <v-snackbar
          v-model="pwError"
        >
          Invalid password.

          <template v-slot:action="{ attrs }">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="pwError = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
      <template v-else>
        <v-app-bar      
          absolute
          height="600"
          app 
          class="image-header"
          color="blue darken-4"
          :src="$url+'/images/icon.jpg'"
          elevation="0"
        >

        </v-app-bar>
        
        <v-main>
          <!-- <v-sheet height="1000px"></v-sheet> -->
          <router-view/>
        </v-main>
        <v-footer class="pa-0">
          <v-card class="pa-7" flat dark color="#15264c" tile width="100%">
            <v-card-text class="text-center">
              <small>&copy; Copyright {{ new Date().getFullYear() }} Royal Caribbean International</small>
            </v-card-text>
          </v-card>
          
        </v-footer>
      </template>
    </template>
    
  </v-app>
</template>

<script>
if(window.location.protocol != 'https:' && !window.location.href.includes("localhost")) {
  location.href =   location.href.replace("http://", "https://");
}
export default {
  name: 'App',

  data: () => ({
    loggedIn:false,
    password:'',
    pwError:false,
    loading:true
  }),
  beforeMount(){
    this.$axios.post('/api/index.php',{
      action:'LoadShipData',
      route:'ship'
    }).then(response=>{
      var data=response.data;
      if(data.result==1){
        this.$store.state.shipData=data.shipData;
        this.loggedIn=data.shipData.loggedIn;
      }
      this.loading=false;
    })
  },
  methods:{
    login(){
      this.$axios.post('/api/index.php',{
        action:'LogIn',
        route:'ship',
        password:this.password
      }).then(response=>{
        var data=response.data;
        if(data.result==1){
          if(!data.loggedIn){
            this.pwError=true;
          }else{
            this.loggedIn=true;
          }
        }
      })
    }
  }
};
</script>
<!-- 
<style>

  .image-header{
    background-size: contain !important;
  }

</style> -->
