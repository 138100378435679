<template>
  <div>

    <v-stepper flat v-model="formFlow">
      <v-stepper-header v-if="!submitSuccess" elevation="0">
        <v-stepper-step
          step="1"
          editable          
        >
          Welcome!
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="2"
          editable                          
        >
          Choose your assets
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step editable step="3">
          Enter your information
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step editable step="4">
          Review & submit request
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">

          <WelcomeText/>
          <div class="text-center">
            <v-btn color="primary" @click="formFlow++">Choose Your Assets</v-btn>            
          </div>          
        </v-stepper-content>

        <v-stepper-content step="2">        
          <v-card
            class="mb-4 pa-3"
            outlined
          >          
            <div>
              <v-text-field
                label="Search Assets"
                v-model="searchTerm"
                placeholder="At least 3 characters..."
                prepend-icon="mdi-magnify"
                clearable
                dense
                outlined
              ></v-text-field>
            </div>  
            <v-badge
              class="mt-3"
              color="#ffb500"
              offset-x="20"
              bordered
              offset-y="10"
              v-for="(button,btnId) in buttons"
              :key="'form-btn-type-'+btnId"      
              :content="selected[button.slug].length"
              :value="selected[button.slug].length>0?selected[button.slug]:''"
            >            
              <v-btn 
                :outlined="currentPage!=button.page?true:false"
                depressed
                class="mx-2"
                color="primary" 
                @click="currentPage=button.page"
              >
                {{ button.title }}
              </v-btn>
            </v-badge>

            <v-card
              flat
              tile
            >
              <v-window
                v-model="currentPage"                                          
                class="overflow-y-auto overflow-x-hidden pa-5"
              >
                <v-window-item
                  v-for="(item,itemId) in buttons"
                  :key="`card-${itemId}`"
                >
                  <v-card
                    color="white"                    
                    height="600"
                    class="overflow-y-auto overflow-x-hidden pa-5"
                  >                    
                    <VideoGallery gallery-type="Videos" :data-source="filteredData.videos" v-model="selected.videos" v-if="item.slug=='videos'"/>
                    <VideoGallery gallery-type="B-Roll" :data-source="filteredData.broll" v-model="selected.bRoll" v-if="item.slug=='bRoll'"/>
                    <GalleryImage :data-source="filteredData.images" v-model="selected.images" v-if="item.slug=='images'"/>
                    <PressRelease :data-source="filteredData.pr" v-model="selected.pressRelease" v-if="item.slug=='pressRelease'"/>
                    <FactSheet v-model="selected.factsheets" v-if="item.slug=='factsheets'"/>
                  </v-card>
                </v-window-item>
              </v-window>

            </v-card>
          </v-card>
          <div class="text-center">
            <v-btn color="primary" class="mr-2" @click="formFlow--">Welcome Screen</v-btn>
            <v-btn color="primary" @click="formFlow++">Done Picking? Enter your information</v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <RequestForm v-model="requestData" @enter-press="formFlow++"></RequestForm>
          <div class="text-center">
            <v-btn color="primary" class="mr-2" @click="formFlow--">Back to Asset Selection
            </v-btn>
            <v-btn color="primary" @click="formFlow++">Next Step: Review & Submit</v-btn>
          </div>
        </v-stepper-content>   
        
        <v-stepper-content step="4">
          <template v-if="submitSuccess">
            <v-card
              class="mb-4 pa-3 mx-auto"
              :width="$vuetify.breakpoint.width<1078?'90%':'50%'"
              outlined
            >
              <v-card-title>Thank you!</v-card-title>
              <v-card-text>
                <p>
                  Your request was successfully received, and is currently in progress.
                  Once complete, you will receive an email at <b>{{ requestData.email }}</b> with
                  a link* to download a zip file. 
                  <i>*The email may include more than one download link, depending on the number of assets requested.</i> 
                </p>
                <p>
                  Depending on the size and number of assets you chose, it may be several 
                  minutes before you receive the link.  If you have any other questions, please 
                  contact your PR representative. 
                </p>
                <div class="text-center">
                  <v-btn color="primary" @click="startOver">Start Over [{{ restartCounter }}]</v-btn>
                </div>
              </v-card-text>              
            </v-card>
    
          </template>
          <template v-else>
            <SubmitForm
              :email="requestData.email"
              :form-valid="formValid"
              :request-data="selected"
              :userName="requestData.name"
              @return-to="goToSection"
            >
            </SubmitForm>
            <div class="text-center">
              <v-btn color="primary" class="mr-2" @click="formFlow--">Go Back</v-btn>
              <v-btn
                color="success"
                :disabled="formValid.length>0 || processing"
                @click="submitRequest"              
              >
                <v-progress-circular
                  class="mr-2"
                  v-if="processing"
                  indeterminate
                  size="20"
                  color="primary"
                ></v-progress-circular>
                Submit Request
              </v-btn>
            </div>
          </template>
        </v-stepper-content>
      </v-stepper-items>      
    </v-stepper>
  </div>
  
</template>

<script>

  export default {
    name: 'HomeView',
    components:{
      RequestForm: ()=>import('../components/RequestForm.vue'),
      GalleryImage: ()=>import('../components/GalleryImage.vue'),
      WelcomeText: ()=>import('../components/WelcomeText.vue'),
      VideoGallery: ()=>import('../components/VideoGallery.vue'),
      PressRelease: ()=>import('../components/PressRelease.vue'),
      FactSheet: ()=>import('../components/FactSheet.vue'),
      SubmitForm: ()=>import('../components/SubmitForm.vue'),
    },
    data:()=>{
      return{
        searchTerm:"",
        processing:false,
        submitSuccess:false,
        restartCounter:0,
        restartInterval:null,
        selected:{
          pressRelease:[],
          videos:[],
          bRoll:[],
          images:[],
          factsheets:[]
        },
        formFlow: 1,
        agreed:false,
        slide:0,
        currentPage:0,
        buttons:[
          {title:'Press Releases', page:0, slug:'pressRelease', selected:0},
          {title:'Videos',page:1, slug:'videos', selected:0},
          {title:'B-Roll',page:2, slug:'bRoll', selected:0},
          {title:'Images',page:3, slug:'images', selected:0},
          {title:'Fact Sheets',page:4, slug:'factsheets', selected:0}
        ],
        validationErrors:[],
        requestData:{
          name:'',
          email:'',
          agreed:false
        }
      }
    },
    methods:{
      addAllAssets(){
        this.selected={
          pressRelease:[],
          videos:[],
          bRoll:[],
          images:[],
          factsheets:[]
        }
        var shipData=this.$store.state.shipData
        var a;
        var b;
        var slots;
        for(a=0;a<shipData.pr.length;a++){
          this.selected.pressRelease.push(shipData.pr[a].filename)
        }
        for(a=0;a<shipData.videos.length;a++){
          slots=shipData.videos[a].slots
          for(b=0;b<slots.length;b++){
            this.selected.videos.push(slots[b].value)
          }
        }
        for(a=0;a<shipData.broll.length;a++){
          slots=shipData.broll[a].slots
          for(b=0;b<slots.length;b++){
            this.selected.bRoll.push(slots[b].value)
          }
        }
        for(a=0;a<shipData.images.length;a++){
          this.selected.images.push(shipData.images[a].filename)
        }
        for(a=0;a<shipData.factsheets.length;a++){
          this.selected.factsheets.push(shipData.factsheets[a].fileUrl)
        }
      },
      goToSection(page){
        console.log(page);
        this.currentPage=page;
        this.formFlow=2;
      },
      submitRequest(){
        this.processing=true;
        this.$axios.post('/api/index.php',{
          action:'SubmitRequest',
          route:'ship',
          collection:this.selected,
          userInfo:this.requestData
        }).then(response=>{
          var data=response.data;
          if(data.result==1){
            this.selected={
              pressRelease:[],
              videos:[],
              bRoll:[],
              images:[],
              factsheets:[]
            }    
            // this.requestData={
            //   name:'',
            //   email:'',
            //   agreed:false
            // }    
            this.restartCounter=20
            this.restartInterval=setInterval(this.procInt,1000)
            this.submitSuccess=true;
          }
          this.processing=false;
        })
      },
      procInt(){
        this.restartCounter--
        if(this.restartCounter<=0){
          clearInterval(this.restartInterval)
          window.location.reload()
        }
      },
      startOver(){
        window.location.reload()
      }
    },
    watch:{
      searchTerm(){
        if(this.searchTerm==null){
          this.searchTerm=""
        }
      }
    },
    computed:{
      filteredData(){        
        if(this.searchTerm==null || this.searchTerm.length<3){
          return this.$store.state.shipData
        }
        let data={
          videos:[],
          broll:[],
          factsheets:[],
          images:[],
          pr:[]
        }
        let term=this.searchTerm.toLowerCase()
        let shipData=this.$store.state.shipData
        var a;
        Object.keys(this.$store.state.shipData).forEach((key)=>{
          switch(key){
            case "images":
              for(a=0;a<shipData.images.length;a++){
                let images=shipData.images[a]
                if(
                  images.title.toLowerCase().includes(term) ||
                  images.tags.toLowerCase().includes(term)
                ){
                  data.images.push(images)
                }
              }
              break;
            case "videos":
              for(a=0;a<shipData.videos.length;a++){
                let video=shipData.videos[a]
                if(
                  video.description.toLowerCase().includes(term) ||
                  video.title.toLowerCase().includes(term) ||
                  video.tags.toLowerCase().includes(term)
                ){
                  data.videos.push(video)
                }
              }
              break;
            case "broll":
              for(a=0;a<shipData.broll.length;a++){
                let broll=shipData.broll[a]
                if(
                  broll.description.toLowerCase().includes(term) ||
                  broll.title.toLowerCase().includes(term) ||
                  broll.tags.toLowerCase().includes(term)
                ){
                  data.broll.push(broll)
                }
              }
              break;
            case "pr":
              for(a=0;a<shipData.pr.length;a++){
                let pr=shipData.pr[a]
                if(
                  pr.descriptor.toLowerCase().includes(term) ||
                  pr.title.toLowerCase().includes(term) ||
                  pr.tags.toLowerCase().includes(term)
                ){
                  data.pr.push(pr)
                }
              }
              break;
          }
        })
        return data
      }, 
      formValid(){
        var slotCounter=0; 
        var errs=[];
        Object.keys(this.selected).forEach(function(key){
          if(this.selected[key].length>0){
            slotCounter++;
          }
        }.bind(this));

        if(slotCounter==0){
          errs.push("At least one item needs to be added to your collection.");
    
        }
        
        if(this.requestData.name.length==0){
          errs.push("Your name is required.");
        }

        if(this.requestData.email.length==0){
          errs.push("Your email address is required.");
        }else{
          if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.requestData.email)){
            errs.push("Your email address does not appear to be valid")
          }
        }

        // if(!this.requestData.agreed){
        //   errs.push("You must agree to the terms and conditions");
        // }

        return errs;
      }
    }

  }
</script>

<style>
  .v-stepper__header{
    box-shadow: none !important;
  }

  .v-card__title{
    
    word-wrap: break-word !important;
    word-break: keep-all;
  }

  @media only screen and (max-width: 959px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: flex !important;
      margin-left: 5px;
      font-size: .8em;
    }
  }
</style>
